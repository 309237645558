<template>
  <van-popup
    class="vantPop"
    v-model:show="showPop"
    :closeable="closeable"
    :close-on-click-overlay="false"
    close-icon-position="top-right"
    @close="$emit('cancel')"
  >
    <div class="popup">
      <div class="content">
        <div class="title">{{ title }}</div>
        <div class="desc">{{ desc }}</div>
      </div>
      <div class="btn">
        <div v-if="showCancel" @click="$emit('cancel')">{{ btncon.cancel }}</div>
        <div @click="$emit('confirm')" :style="{ color: confirmColor }">{{ btncon.success }}</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { reactive, computed, toRefs } from 'vue';

export default {
  props: {
    // 是否显示
    show: {
      type: Boolean,
      default: false
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 描述
    desc: {
      type: String,
      default: ''
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    btncon: {
      type: Object,
      default: {
        cancel: '取消',
        success: '确认'
      }
    },
    confirmColor: {
      type: String,
      default: '#fff'
    },
    closeable: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    const { show } = toRefs(props);
    const showPop = computed({
      get: () => {
        return show.value;
      },
      set: v => {
        context.emit('update:show', v);
      }
    });
    return {
      showPop
    };
  }
};
</script>

<style lang="scss" scoped>
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;

  .content {
    padding: 16px 20px 20px 20px;

    .title {
      font-size: 18px;
      font-weight: 500;
    }
    .desc {
      font-weight: 400;
      margin-top: 20px;
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #494359;

    div {
      flex: 1;
      margin: 15px 0;
    }

    div:nth-child(2) {
      border-left: 1px solid #494359;
    }
  }
}
</style>
<style>
.vantPop {
  background: transparent !important;
}
</style>
