import OSS from 'ali-oss';
import { nftUserShareSTS, nftUserShareSTSCommon } from '@/service/home';
export default {
  _dataURLtoBlob(dataurl) {
    // debugger
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {
      type: mime
    });
  },

  uploadHandle64(img, func) {
    nftUserShareSTS()
      .then(res => {
        if (res.status === 0) {
          return res.data;
        } else {
          throw Error('获取sts出错');
        }
      })
      .then(res => {
        const {
          region,
          bucket,
          token: { AccessKeySecret, AccessKeyId, SecurityToken },
          filePath
        } = res;

        // let _fileBlob = this._dataURLtoBlob(img);
        // blob转arrayBuffer
        const reader = new FileReader();
        reader.readAsArrayBuffer(img);
        reader.onload = function (event) {
          // 配置
          const client = new OSS({
            region,
            accessKeyId: AccessKeyId,
            accessKeySecret: AccessKeySecret,
            stsToken: SecurityToken,
            bucket
          });

          // arrayBuffer转Buffer
          const buffer = new OSS.Buffer(event.target.result);
          // 上传
          client
            .put(filePath, buffer)
            .then(function (result) {
              console.log(result);
              func(result.url + '?timestamp=' + Date.parse(new Date()));
            })
            .catch(function (err) {
              console.log(err);
              func('');
            });
        };
      });
  },

  uploadHandle64Common(img, func) {
    nftUserShareSTSCommon()
      .then(res => {
        if (res.status === 0) {
          return res.data;
        } else {
          throw Error('获取sts出错');
        }
      })
      .then(res => {
        const {
          region,
          bucket,
          token: { AccessKeySecret, AccessKeyId, SecurityToken },
          filePath
        } = res;

        // let _fileBlob = this._dataURLtoBlob(img);
        // blob转arrayBuffer
        const reader = new FileReader();
        reader.readAsArrayBuffer(img);
        reader.onload = function (event) {
          // 配置
          const client = new OSS({
            region,
            accessKeyId: AccessKeyId,
            accessKeySecret: AccessKeySecret,
            stsToken: SecurityToken,
            bucket
          });

          // arrayBuffer转Buffer
          const buffer = new OSS.Buffer(event.target.result);
          // 上传
          client
            .put(filePath, buffer)
            .then(function (result) {
              console.log(result);
              func(result.url + '?timestamp=' + Date.parse(new Date()));
            })
            .catch(function (err) {
              console.log(err);
              func('');
            });
        };
      });
  },

  getUploadData() {
    return nftUserShareSTS()
      .then(res => {
        if (res.status === 0) {
          return res.data;
        } else {
          throw Error('获取sts出错');
        }
      })
      .then(res => {
        const {
          region,
          bucket,
          token: { AccessKeySecret, AccessKeyId, SecurityToken },
          filepath,
          link
        } = res;
        const client = new OSS({
          region,
          accessKeyId: AccessKeyId,
          accessKeySecret: AccessKeySecret,
          stsToken: SecurityToken,
          bucket
        });
        return { client, filepath, link };
      });
  },

  // 上传图片方法
  uploadHandle(fileData, options = {}) {
    return this.getUploadData().then(res => {
      const { client, filepath } = res;
      fileData.filepath = filepath;
      return client.multipartUpload(filepath, fileData.file, options);
    });
  },

  getExtname(filename) {
    var index = filename.lastIndexOf('.');
    var suffix = filename.substr(index);
    return suffix;
  }
};
