<template>
  <div id="app">
    <router-view
      class="router-view"
      :class="{ show_nav: showNav }"
      :style="webBox ? 'padding-top:1.34rem' : ''"
      v-slot="{ Component }"
    >
      <!-- <transition :name="transitionName"> -->
      <component :is="Component" />
      <!-- </transition> -->
    </router-view>

    <nav-bar v-if="showNav" />

    <div v-if="show" class="wxtip">
      <span class="wxtip-icon"></span>
      <p class="wxtip-txt">请点击右上角<br /><span>请在外部浏览器中打开</span></p>
    </div>
    <van-icon
      v-show="showBack"
      class="icon_back"
      size="0.854rem"
      style="margin-left: 20px; position: fixed; z-index: 998"
      :style="webBox ? 'top:1.6rem' : 'top:0.54rem'"
      :name="icon_back"
      @click="goBack"
    />
    <div v-if="webBox" class="isWeb">
      <div class="logoBox">
        <img class="logoIcon" src="./imgs/icon/ico-kmh.png" />下载看漫App，操作更便捷
      </div>

      <div class="closeBox">
        <div class="downBtn" @click="downLoad">下载</div>
        <img class="closeIcon" @click="closeWebBox" src="./imgs/icon/closeIcon.png" />
      </div>
    </div>
    <!-- 预付款未抢到盲盒 -->
    <popup
      v-model:show.sync="showNotGet"
      title="很遗憾未抢到盲盒"
      desc="活动实在太火爆了，你的预付款未成功购买到盲盒。预付款将已以“预付款自动抢购特权”的形式退回至“我的-我的特权”中，请注意查收。"
      :btncon="{ cancel: '取消', success: '查看' }"
      @confirm="toView"
      @cancel="cancelView"
    />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { nftUtils, localStorageUtil } from '@/utils';
import navBar from '@/components/NavBar';
import moment from 'moment';
import { checkpresalefailispopup, updatepresalefailpopupstatus } from '@/service/order';
import popup from '@/components/popup/index.vue';

export default {
  components: {
    popup,
    navBar
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      show: false,
      showNav: false,
      showBack: false,
      showNotGet: false,
      isLogin: false,
      webBox: true,
      hasMove: false, // 是否离开了页面
      isApp: nftUtils.isInApp()
    });

    watch(
      () => router.currentRoute.value.path,
      (newValue, oldValue) => {
        // 路由发生变化，在用户已经付了预付款后，中了盲盒弹出提示
        // state.isLogin = nftUtils.isLogin();
        // if (state.isLogin) {
        //   checkpresalefailispopup().then(res => {
        //     res.data && (state.showNotGet = true);
        //     //  console.log(res.data, state.showNotGet);
        //   });
        // }
      },
      { immediate: true }
    );
    onMounted(() => {
      nftUtils.noPull();
      setTimeout(() => {
        nftUtils.noLeftBack();
      }, 1000);

      let float_down = localStorageUtil.get('float_down');
      let now = moment(new Date()).format('YYYY-MM-DD');

      state.webBox = !state.isApp && float_down !== now;
      store.state.webBox = state.webBox;
      //在微信内显示引导
      if (weixin) {
        if (
          window.location.href.indexOf('login') > -1 ||
          window.location.href.indexOf('/authentication') > -1
        ) {
          state.show = false;
        } else {
          state.show = true;
        }
      }
      //判断活动结束是否有抢到
    });

    if (document.getElementById('Loading')) document.getElementById('Loading').remove();

    //判断是否在微信内
    const UA = navigator.userAgent;
    const weixin = !!/MicroMessenger/i.test(UA);

    const goBack = () => {
      if (window.history.length <= 1) {
        router.push({ path: '/', replace: true });
      } else {
        router.go(-1);
      }
    };

    //刷新用户离开页面
    function handleVisibilityChange() {
      let routeName = ['home', 'user', 'announcement'];
      if (document.hidden) {
        // document.title = '离开了';
        state.hasMove = true;
      } else {
        // document.title = '在这';
        // getuser();

        let name = route.name;
        if (nftUtils.isIOS() && !nftUtils.isInApp() && state.hasMove && routeName.includes(name)) {
          state.hasMove = false;
          location.reload(true);
        }
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange, false);

    // 查看
    const toView = () => {
      router.push({ path: '/privilege' });
      cancelView();
    };
    const cancelView = async () => {
      state.showNotGet = false;
      const res = await updatepresalefailpopupstatus();
    };

    router.beforeEach(to => {
      // 不在APP内时，显示返回按钮，主页和我的页面不显示
      state.showNav = to.meta.showNav;
      state.showBack = !nftUtils.isInApp() && to.meta.index > 1;

      // 不在wx中时 把在wx内打开的链接域名 替换掉真正的域名
      if (!nftUtils.isInWx() && window.location.href.indexOf('yx03') > -1) {
        let newUrl = window.location.href.replace(
          'https://collect.yx03.com/',
          'https://collect.mh51.com/'
        );
        window.location = newUrl;
      }
      // if (history.scrollRestoration) {
      //   history.scrollRestoration = 'manual';
      // }
    });

    const downLoad = () => {
      nftUtils.setcnzzRecordByBtn('下载看漫app');
      window.location.href =
        'https://app.321mh.com/app/scheme?pkgname=com.wbxm.icartoon&channeCode=nft&ios_scheme=tkanmanapp%3A%2F%2Fgoto%3Fpage%3Dweb%26url%3Dhttps%253A%252F%252Fcollect.mh51.com%252F&android_schema=tkanmanapp%3A%2F%2Fgoto%3Fpage%3Dweb%26url%3Dhttps%253A%252F%252Fcollect.mh51.com%252F';
    };

    const closeWebBox = () => {
      state.webBox = false;
      let now = moment(new Date()).format('YYYY-MM-DD');
      localStorageUtil.set('float_down', now);
      store.state.webBox = state.webBox;
    };

    return {
      ...toRefs(state),
      goBack,
      toView,
      cancelView,
      downLoad,
      closeWebBox,
      icon_back: require('./imgs/icon/icon_24_arrow_grey.png')
    };
  }
};
</script>

<style lang="scss">
@import './styles/mixin.scss';
html,
body {
  background-color: $bc;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
img {
  pointer-events: none;
}
#app {
  height: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: $fc;
}
input {
  /*背景透明*/
  background-color: transparent;
  /*去边框*/
  border: 0;
  /*去外边框*/
  outline: none;
  /*文本颜色*/
  color: white;
}

input:-webkit-autofill {
  /*自动填入文本颜色*/
  -webkit-text-fill-color: #ffffff !important;
  /*自动填入光标颜色*/
  caret-color: white;
  /*背景透明；原理：动画由透明变有背景色；参数：背景色属性、动画时间、动画速度曲线、延迟时间*/
  transition: background-color 0s linear 3600s;
}
.van-dialog {
  // background: #1c172a;
  color: #fff;
  --van-dialog-has-title-message-text-color: #1c172a !important;
  --van-dialog-background-color: #1c172a !important;
  --van-dialog-confirm-button-text-color: #fff !important;
  .van-button--default {
    background-color: #1c172a;
  }
  .van-hairline--top {
    border-top: 1px solid #000;
  }
}
.show_nav {
  &::after {
    display: block;
    //height: calc(25vh);
    // height: calc(5vh);
    height: 82px;
    content: '';
    visibility: hidden;
  }
}
.router-view {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 500ms;
  position: absolute;
  backface-visibility: hidden;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.van-badge--fixed {
  z-index: 1000;
}
.page-bg {
  position: absolute;
  background-image: url('./imgs/comm/pic_bg.png');
  background-size: 100%;
  height: 241px;
  width: 100%;
}
.submit-btn {
  height: 44px !important;
  width: 335px !important;
  margin: 32px 20px !important;
  --van-button-plain-background-color: linear-gradient(to right, #8ee4c8, #30daec);
  --van-button-default-border-color: $fc;
  --van-button-border-width: 1px;
  --van-button-default-color: #1c172a;
  --van-button-border-radius: 4px;
  font-weight: bold;
  font-size: 20px;
}

// swiper-slide
.wxtip {
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9998;

  .wxtip-icon {
    width: 52px;
    height: 67px;
    background: url('./imgs/comm/weixin-tip.png') no-repeat;
    display: block;
    position: absolute;
    right: 30px;
    top: 20px;
  }

  .wxtip-txt {
    padding-top: 107px;
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
  }
}

.isWeb {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  height: 50px;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;

  .logoIcon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .closeBox,
  .logoBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;

    .downBtn {
      width: 64px;
      height: 28px;
      border-radius: 8px 8px 8px 8px;
      line-height: 28px;
      text-align: center;
      border: 1px solid #ffffff;
    }

    .closeIcon {
      padding: 3px;
      width: 16px;
      height: 16px;
      margin-left: 15px;
      pointer-events: auto;
    }
  }
}
</style>
