import { http } from '@/api';
/**
 * 首页列表
 * @param {*} params
 * @returns
 */
export function homeList(params) {
  return http.get('/v1/index/list', params);
}

// 藏品详情
export function getCollectionsInfo(params) {
  return http.get('/v1/user/getUserCollectionsInfo', params);
}

/**
 * 单品详情
 * @param {*} params
 * @returns
 */
export function produceDetail(params) {
  return http.get('/v1/index/itemdetails', params);
}

/**
 * 盲盒详情
 * @param {*} params
 * @returns
 */
export function boxDetail(params) {
  return http.get('/v1/index/collectionsdetails', params);
}

/**
 * 校验订单是否支付成功
 * @param {*} params
 * @returns
 */
export function checkorderispay(params) {
  return http.post('/v1/orders/checkorderispay', params);
}

/**
 *  开盲盒
 * @param {*} params
 * @returns
 */
export function opencollection(params) {
  return http.get('/v1/user/opencollection', params);
}

/**
 *  上传图片授权地址
 * @param {*} params
 * @returns
 */
export function nftUserShareSTS(params) {
  return http.get('/v1/sts/nftusersharests', params);
}

export function nftUserShareSTSCommon(params) {
  return http.get('/v1/sts/nftusersharests2', params);
}

/**
 *  获取公告接口列表
 * @param {*} params
 * @returns
 */
export function getnoticelist(params) {
  return http.get('/v1/notice/getnoticelist', params);
}
/**
 *  获取公告详情接口
 * @param {*} params
 * @returns
 */
export function getnoticeinfo(params) {
  return http.get('/v1/notice/getnoticeinfo', params);
}
/**
 *  获取公告阅读状态
 * @param {*} params
 * @returns
 */
export function getnoticereadstatus(params) {
  return http.get('/v1/notice/getnoticereadstatus', params);
}
/**
 *  获取合成详情
 * @param {*} params
 * @returns
 */
export function getmergeitems(params) {
  return http.get('/v1/items/getmergeitems', params);
}
export function getmergeitemsv2(params) {
  return http.get('/v1/items/getmergeitemsv2', params);
}

/**
 *  配置接口
 * @param {*} params
 * @returns
 */
export function getmergeconfig(params) {
  return http.get('/v1/scores/exchangeconfig', params);
}
/**
 *
 * @param {*} params
 * @returns
 */
export function getusercollectionlist(params) {
  return http.get('/v1/user/getusercollectionlist', params);
}
/**
 *获取baner接口列表
 * @param {*} params
 * @returns
 */
export function getbannerlist(params) {
  return http.get('/v1/banner/getbannerlist', params);
}
/**
 *历史列表
 * @param {*} params
 * @returns
 */
export function historylist(params) {
  return http.get('/v1/index/historylist', params);
}

// 获取小红点
export function gethotdot(params) {
  return http.get('/v2/user/gethotdot', params);
}

// 删除小红点
export function delhotdot(params) {
  return http.post('/v2/user/delhotdot', params);
}

// 抽签-报名
export function luckydraw(params) {
  return http.post('/v1/drowlots/enroll', params);
}

// 抽签-报名抽签详情
export function getenrollinfo(params) {
  return http.get('/v1/drowlots/getenrollinfo', params);
}

// 邀请助力接口（获取collections_id和my_id）
export function invitehelp(params) {
  return http.post('/v1/drowlots/invitehelp', params);
}

// 抽签-给Ta助力
export function gavehelp(params) {
  return http.post('/v1/drowlots/gavehelp', params);
}

// 抽签弹窗通知
export function checkDrawPop(params) {
  return http.get('/v1/drawlots/checkuserdrawlotspopup', params);
}

//确认抽签结果已弹窗
export function confirmDrawPop(params) {
  return http.post('/v1/drawlots/updateuserdrawlotspopup', params);
}
//审核图片上传
export function audituploadsts(params) {
  return http.get('/v1/user/audituploadsts', params);
}
//审核图片上传按钮显示
export function indexentranceshow(params) {
  return http.get('v1/user/indexentranceshow', params);
}
//审核图片上传
export function uploadimagesave(params) {
  return http.post('/v1/user/uploadimagesave', params);
}

// 许愿池小红点 是否显示小红点
export function getisshowredmark(params) {
  return http.get('/v1/makewish/getisshowredmark', params);
}
//
export function getwishdetailid(params) {
  return http.get('/v1/items/getwishdetailid', params);
}
