/**
 * Url相关
 * 设置页面分享信息
 */

import clientUtil from './client'; // 浏览器特性pName之类的

class Dom {
  constructor() {}

  /**
   * 设置页面分享信息
   * @param share_title, share_content, share_image
   */
  setDomShareMeta({ share_title, share_content, share_image, title, content, image }) {
    const domKeyword = document.querySelector('meta[name="keywords"]');
    const domDescription = document.querySelector('meta[name="description"]');

    const domName = document.querySelector('meta[itemprop="name"]');
    const domDescription1 = document.querySelector('meta[itemprop="description"]');
    const domImage = document.querySelector('meta[itemprop="image"]');

    domKeyword.content = title || share_title;
    domName.content = title || share_title;

    domDescription.content = content || share_content;
    domDescription1.content = content || share_content;

    domImage.content = image || share_image;
  }
  // 判断元素是够在视窗内
  elementIsVisibleInViewport(el, partiallyVisible = false) {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  }
  // 禁止dom滚动
  noscroll() {
    let scrollTop;
    if (document.scrollingElement.scrollTop) {
      scrollTop = document.scrollingElement.scrollTop;
    } else {
      scrollTop = 0;
    }
    document.body.style.top = -scrollTop + 'px';
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    return scrollTop;
  }
  // 还原dom高度
  overlayClick(scrollTop) {
    document.body.style.overflow = 'auto';
    document.body.style.position = '';
    document.scrollingElement.scrollTop = scrollTop;
    console.log('overlayClick');
  }
  // 键盘弹起事件
  keyboardTrigger(callback) {
    const clientInfo = clientUtil.getClientInfo();
    const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
    function οnresize() {
      //键盘弹起与隐藏都会引起窗口的高度发生变化
      const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
      callback(resizeHeight < originalHeight);
    }
    if (clientInfo.isIos) {
      document.body.addEventListener('focusin', () => {
        callback(true);
      });
      document.body.addEventListener('focusout', () => {
        callback(false);
      });
    } else {
      window.addEventListener('resize', οnresize);
    }
  }
  // 返回顶部
  scrollToTop() {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 0) {
      window.requestAnimationFrame(this.scrollToTop.bind(this));
      window.scrollTo(0, scrollTop - scrollTop / 4);
    }
  }
  anchor(scrollTo) {
    window.scrollTo(0, scrollTo);
  }
  // 创建组件dom节点
  createComponentInstanceDom(createApp, component, symbolClassName) {
    const domDiv = document.createElement('div');
    domDiv.className = symbolClassName;
    document.body.appendChild(domDiv);

    const appTemp = createApp(component);
    const instance = appTemp.mount(`.${symbolClassName}`);
    domDiv.appendChild(instance.$el);
    return instance;
  }
}

export default new Dom();
