export default {
  loginInfo(state, payload) {
    state.loginInfo = payload.loginInfo;
  },
  homeList(state, payload) {
    state.homeList = payload.homeList;
  },
  homehistoryList(state, payload) {
    state.homehistoryList = payload.data;
  },
  changeShowPrepaidHome(state, payload) {
    state.showPrepaidHome = payload;
  },
  // 改版tab 公告红点
  changeGgTabPoint(state, payload) {
    state.ggPoint = payload.data;
  },
  // 改版转赠 我的红点
  changeSendPoint(state, payload) {
    if (payload.data.receive_user || payload.data.send_user) {
      state.sendPoint = 1;
    } else {
      state.sendPoint = 0;
    }
    state.pointData = payload.data;
    // state.sendPoint = payload.data;
  },
  resetSendPoint(state, payload) {
    state.sendPoint = 0;
  },

  changeWishPoint(state, payload) {
    state.wishPoint = payload.data;
  }
};
