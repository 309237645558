import { createApp } from 'vue';
import App from './App.vue';
import { nftUtils } from './utils';
import {
  Image,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  ActionSheet,
  Divider,
  Popup,
  Overlay,
  Loading,
  Dialog,
  ContactCard,
  Form,
  AddressEdit,
  AddressList,
  Field,
  CellGroup,
  Cell,
  SwipeCell,
  Icon,
  Stepper,
  Card,
  Checkbox,
  CheckboxGroup,
  Button,
  Swipe,
  SwipeItem,
  PullRefresh,
  List,
  Tab,
  Tabs,
  SubmitBar,
  Toast,
  Skeleton,
  Lazyload,
  Picker,
  CountDown,
  Badge,
  Area,
  NoticeBar,
  Uploader,
  DropdownMenu,
  DropdownItem,
  Step, Steps
} from 'vant';
import router from './router';
import store from './store';
// import '@/styles/index.scss';
import 'amfe-flexible';
import installPlugins from './plugins';
import FastClick from 'fastclick';
import 'vant/lib/index.css'; // 全局引入样式

import VueLuckyCanvas from '@lucky-canvas/vue';

import '@/assets/fonts/font.css';

// import { clientUtil, urlUtil } from '@/utils';
// import Scheme from '@acgn/flyer/src/scheme/index';
// const schemeUtil = new Scheme({ modules: { clientUtil, urlUtil } });
FastClick.prototype.focus = function (targetElement) {
  let length;
  if (
    targetElement.setSelectionRange &&
    targetElement.type.indexOf('date') !== 0 &&
    targetElement.type !== 'time' &&
    targetElement.type !== 'month'
  ) {
    length = targetElement.value.length;
    targetElement.focus();
    targetElement.setSelectionRange(length, length);
  } else {
    targetElement.focus();
  }
};
FastClick.attach(document.body);

const app = createApp(App);
installPlugins(app);

app
  .use(Image)
  .use(ActionBarButton)
  .use(ActionBarIcon)
  .use(ActionBar)
  .use(ActionSheet)
  .use(Divider)
  .use(Popup)
  .use(Overlay)
  .use(Loading)
  .use(Dialog)
  .use(Toast)
  .use(ContactCard)
  .use(Form)
  .use(Picker)
  .use(AddressEdit)
  .use(AddressList)
  .use(Field)
  .use(CellGroup)
  .use(Cell)
  .use(SwipeCell)
  .use(Icon)
  .use(Stepper)
  .use(Card)
  .use(Button)
  .use(Swipe)
  .use(SwipeItem)
  .use(PullRefresh)
  .use(List)
  .use(Tab)
  .use(Tabs)
  .use(SubmitBar)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Skeleton)
  .use(Lazyload)
  .use(CountDown)
  .use(Badge)
  .use(Area)
  .use(NoticeBar)
  .use(Uploader)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Step)
  .use(Steps);

app.use(store);
app.use(VueLuckyCanvas);
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
    nftUtils.setcnzzRecordByPage(to.meta.title);
  }
  if (to.meta.needLogin) {
    if (nftUtils.isLogin()) {
      next();
    } else {
      router.push({ path: '/login' });
    }
  } else {
    next();
  }
});
app.use(router);
app.mount('#app');
// let platformInfo = schemeUtil.platformInfo();
// const scheme_Adr = platformInfo.scheme_Adr;
// let path = `${scheme_Adr}://app?callback=nosharebtn`;
// schemeUtil.createIframe(path, {
//   id: 'toApp'
// });
export default app;
