import util from './utils'; // 普通杂类方法

/**
 * localStorage相关
 */

class LocalStorage {
  constructor() {}

  get(key) {
    return localStorage.getItem(key);
  }

  set(key, data) {
    localStorage.setItem(key, data);
  }

  getItem(key, def = undefined, ignore = false) {
    let o = localStorage.getItem(key);
    if (!o) {
      return def;
    }
    if (/"_t":/.test(o)) {
      let { _t, _d } = JSON.parse(o);
      console.log(key, '缓存时间', new Date(_t), '过期', _t < +new Date());
      if (!ignore && _t < +new Date()) {
        console.log('缓存过期');
        this.del(key);
        return def;
      } else {
        console.log('使用缓存');
        return _d;
      }
    } else {
      console.log('没有缓存时间');
      return JSON.parse(o || JSON.stringify(def));
    }
  }

  /**
   * 设置
   * @param key
   * @param data
   * @param expires 单位分钟
   */
  setItem(key, data, expires = 30) {
    let o = {
      _t: expires * 6e4 + new Date().getTime(),
      _d: data
    };
    localStorage.setItem(key, JSON.stringify(o));
  }

  del(key) {
    localStorage.removeItem(key);
  }

  changeItem(key, data) {
    let obj = this.getItem(key);
    if (util.isEmptyObject(obj)) {
      return data;
    }
    // 更新信息
    Object.assign(obj, data);
    this.setItem(key, obj);
    return obj;
  }

  clearAll() {
    localStorage.clear();
  }
}

export default new LocalStorage();
