import http from './http';

/**
 * 用户服务
 */
const api = {
  // 获取用户信息
  getUserInfo(data, config) {
    return http.get(`/v3/userinfo/getuserinfobytoken`, data, config);
  },

  // 配置微信分享
  setWxShare(data) {
    /*** shareact.womanhua.com 该域名只在漫画台微信公众平台认证 ***/
    data.productname = 'mht';
    /******/
    return http.get('https://kanmanapi-main.321mh.com' + '/v1/getWeixinShareTicket', data, {
      noCache: true
    });
  },
  // 获取付费页漫画信息
  getcomicinfo(data) {
    data.productname = 'mht';
    return http.get('https://comic.321mh.com/app_api/v5/getcomicinfo_charge_status/', data, {
      noCache: true
    });
  },
  // app收藏接口 活动config配置userApi
  getuserrecord(data) {
    return http.post(window.configs['fe.userApi'] + '/app_api/v5/getuserrecord/', data);
  },
  // app设置用户阅读和收藏历史接口
  setusercollect(data) {
    data.action = 'add';
    return http.post(window.configs['fe.userApi'] + '/app_api/v5/setusercollect/', data);
  },
  // 获取验证码
  sendCode(data) {
    return http.post('https://sms.321mh.com/user/v1/sendsms', data);
  },
  // 知音验证验证码
  mobilevclogin(data) {
    return http.post('https://mkxq.zymk.cn' + '/user/v1/mobilebind', data);
  },
  // 知音通过autoToken获取手机号码
  getMobile(data) {
    return http.post('https://getuserinfo-globalapi.zymk.cn/app_api/v5/getusermobile/', data);
  },
  // 发送验证码
  sendsms(data, header) {
    return http.post(`https://m.kanman.com/api/sendsms`, data, header);
  },
  // 获取用户所有信息
  loginUser(data, header) {
    return http.get(`https://getuserinfo.321mh.com/app_api/v5/getuserinfo`, data, header);
    // return http.get(`http://47.98.152.225:9528/api/v1/web/getuserinfo`, data)
  },
  // 验证验证码
  mobilebind(data, header) {
    return http.post(`https://m.kanman.com/api/mkxq/mobilebind/`, data, header);
  },
  // 获取黑金免费卡畅读漫画
  getHjFreeComics(data, config) {
    return http.get(
      'https://getcomicfreecoupon.321mh.com/v2/comic/getallfreecardcomics',
      data,
      config
    );
  },
  // 看漫系android 支付宝支付
  getPayOrderAndroid(apiUrl, data) {
    return http.post(apiUrl, data);
  },
  // 微信支付
  getWeiXinPayOrder(data, url) {
    return http.get(url, data);
  }
};

export { http, api };
