import { http } from '@/api';

/**
 * 购买接口，生成订单
 * @param {*} params
 * @returns
 */
export function pay(params) {
  return http.post('/v1/pay', params);
}
/**
 * 取消订单
 * @param {*} params
 * @returns
 */
export function cancelOrders(params) {
  return http.post('/v1/orders/cancelorders', params);
}
/**
 * 获取订单列表
 * @param {*} params
 * @returns
 */
export function getOrdersList(params) {
  return http.get('/v1/orders/getorderslist', params);
}

/**
 *  获取支付方式

 * @param {*} params
 * @returns
 */
export function payways(params) {
  return http.get('/v1/pay/ways', params);
}

/**
 *  获取用户特权


 * @param {*} params
 * @returns
 */
export function getuserprivilegelist(params) {
  return http.get('/v1/user/getuserprivilegelist', params);
}
/**
 * 购买接口，生成订单
 * @param {*} params
 * @returns
 */
export function payinadvance(params) {
  return http.post('/v1/payinadvance', params);
}
/**
 * 校验盲盒失败弹窗
 * @param {*} params
 * @returns
 */
export function checkpresalefailispopup(params) {
  return http.post('/v1/orders/checkpresalefailispopup', params);
}

/**
 * 确认盲盒失败通知已弹窗
 * @param {*} params
 * @returns
 */
export function updatepresalefailpopupstatus(params) {
  return http.post('/v1/orders/updatepresalefailpopupstatus', params);
}
/**
 * 订单退款申请
 * @param {*} params
 * @returns
 */
export function refundorders(params) {
  return http.post('/v1/refundorders', params);
}
/**
 * 支付宝wap支付
 * @param {*} params
 * @returns
 */
export function alipay_wap_pay(params) {
  return http.post('/v1/alipay_wap_pay', params);
}
/**
 * 通联支付提交银行卡支付
 * @param {*} params
 * @returns
 */
export function allinpay_cardpay(params) {
  return http.post('/v1/allinpay_cardpay', params);
}
/**
 * 通联支付短信验证码
 * @param {*} params
 * @returns
 */
export function allinpay_verifycode(params) {
  return http.post('/v1/allinpay_verifycode', params);
}
/**
 * 通联支付短信验证码
 * @param {*} params
 * @returns
 */
export function getpaystatus(params) {
  return http.get('/v1/getpaystatus', params);
}
/**
 * 杉德支付h5快捷支付下单
 * @param {*} params
 * @returns
 */
export function sandpay_unionpayh5(params) {
  return http.post('/v1/sandpay_unionpayh5', params);
}
/**
 * 分解藏品列表
 * @param {*} params
 * @returns
 */
export function getdecomposelist(params) {
  return http.get('/v1/scores/getdecomposelist', params);
}

/**
 * 获取我的积分
 * @param {*} params
 * @returns
 */
export function getuserscores(params) {
  return http.get('/v1/scores/getuserscores', params);
}

/**
 * 获取积分明细
 * @param {*} params
 * @returns
 */
export function getscoredetails(params) {
  return http.get('/v1/scores/getscoredetails', params);
}

/**
 * 藏品分解
 * @param {*} params
 * @returns
 */
export function decompose(params) {
  return http.post('/v1/scores/decompose', params);
}

/**
 * 获取批量购买结果
 * @param {*} params
 * @returns
 */
export function getorderresult(params) {
  return http.get('v1/pay/getorderresult', params);
}
