import { createApp } from 'vue';
import LoadingComponent from './PageLoading';
import { domUtil } from '@/utils';

const Loading = {};
Loading.install = function (app) {
  const className = '__app-loading__';
  const instance = domUtil.createComponentInstanceDom(createApp, LoadingComponent, className);

  const loadingFn = {
    show() {
      instance.show = true;
    },
    hide() {
      instance.show = false;
    }
  };

  app.config.globalProperties.$loading = loadingFn;
  app.provide('$loading', loadingFn);
};

export default Loading;
