/**
 * 杂类方法
 * 获得对象类型|函数防抖|配置cnzz统计|判断版本|根据uid拼接用户头像|倒计时|判断浏览器是否支持web格式|漫画背景src拼接
 */

import conf from '../constants'; // 平台基本信息
import stringUtil from './string'; // 字符串处理
import { localStorageUtil } from '@/utils';
import { pay } from "@/service/order";
import { Toast } from "vant";
class Util {
  constructor() {
    this.timeout = null;
  }

  /**
   * @function alipay 支付
   * @param {Object} collections_id id
   */
 async alipay(collections_id,router){
 
     // 判断是否登陆
    if (localStorageUtil.get("accessToken")) {
      // 支付
      const { data, status, message } = await pay({
        collections_id,//: state.productDetail.collections_id,
        pay_type: 11,
        back_url:`${location.origin}/boxopen`
      });
      console.log(data);
      if(status == 0) {
        localStorageUtil.set('orderId',data.orderId);
        location.href = data.url;
      }  else if (status == 401) {
        // 实名认证
        Toast(message);
        setTimeout(() => {
          router.push({ path: `/authentication` });
        }, 1000);
      }else if(status == 404){
        Toast(message);
      }
       else if(status == 409){
        // 有未支付订单
        Toast(message);
        setTimeout(() => {
          router.push({ path: `/order` });
        }, 1000);
      }
    } else {
      router.push({ path: `/login` });
    }

  }


  /**
   * @function getType 获得对象类型
   * @param {Object} origin 原始数据
   * @param {String} type 数据类型 string,number,boolean,undefined,null,object,array,function
   * @return {Boolean} 返回真假
   */
  getType(data) {
    const originType = Object.prototype.toString.call(data);
    const len = originType.length;
    return originType.substring(8, len - 1).toLowerCase();
  }
  // 随机排序漫画
  shuffle(arr) {
    let i = arr.length;
    while (i) {
      let j = Math.floor(Math.random() * i--);
      [arr[j], arr[i]] = [arr[i], arr[j]];
    }
    return arr;
  }
  /**
   * @desc 函数防抖
   * @param func 函数
   * @param wait 延迟执行毫秒数
   * @param immediate true 表立即执行，false 表非立即执行
   */
  debounce(func, wait, immediate) {
    const _this = this;
    return function () {
      let context = this;
      // args = [].slice.call(arguments)
      let args = arguments;

      if (_this.timeout) clearTimeout(_this.timeout);
      if (immediate) {
        let callNow = !_this.timeout;
        _this.timeout = setTimeout(() => {
          _this.timeout = null;
        }, wait);
        if (callNow) func.apply(context, args);
      } else {
        _this.timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      }
    };
  }

  /**
   * 配置cnzz统计
   */
  setcnzzRecord(category, action, label, value) {
    if (window.configs['fe.env'] === 'development') return;
    // _czc.push(["_trackEvent",category,action,label,value,nodeid]);
    // @ts-ignore
    let _czc = window._czc;
    if (_czc) {
      _czc.push(['_trackEvent', category, action, label, value]);
    }
  }
  /**
   * 引入所有components
   * v是传进来的活动文件夹下的所有components
   */
  urlComponent(v) {
    const path = require('path');
    const modules = {};
    v.keys().forEach(key => {
      const name = path.basename(key, '.vue');
      modules[name] = v(key).default || v(key);
    });
    return modules;
  }
  /**
   * 判断版本  0低于 1等于 2高于
   */
  judgeVersion(cur, ver = '0') {
    console.log(cur, ver);
    let _cur = cur.split('.');
    let _ver = ver.split('.');
    let len = _ver.length;
    for (let i = 0; i < len; i++) {
      if (+_cur[i] > +_ver[i]) {
        return 2;
      } else if (+_cur[i] < +_ver[i]) {
        return 0;
      }
      if (i === len - 1 && +_cur[i] === +_ver[i]) {
        return 1;
      }
    }
  }

  /**
   * 根据uid拼接用户头像
   */
  getUserHeadCover(uid, str) {
    if (str === 'qmmh') {
      return stringUtil.process4path(stringUtil.getDirById(uid), conf.image.headQmCover);
    }
    if (str === 'zymk') return stringUtil.process4path(stringUtil.getDirById(uid), conf.image.zymk);
    return stringUtil.process4path(stringUtil.getDirById(uid), conf.image.headCover);
  }

  /**
   * 倒计时
   */
  timerCount(ms = 60, cb) {
    let timer = null;
    countTimer(ms);

    function countTimer(ms) {
      if (ms === 0) {
        clearInterval(timer);
        timer = null;
        cb(ms);
        return;
      }

      cb(ms--);
      timer = setTimeout(() => {
        countTimer(ms);
      }, 1000);
    }
  }

  /**
   * [toDate 任意对象转日期高度容错]
   * @param  {[Object]} obj [要转换的日期]
   * @return {[type]}     [description]
   */
  toDate(obj) {
    if (obj instanceof Date || Object.prototype.toString.call(obj) === '[object Date]') {
      return new Date(obj);
    } else if (!isNaN(obj)) {
      return new Date(parseInt(obj, 10)); // 数字或数字字符串转日期
    } else if (!isNaN(Date.parse(obj))) {
      return new Date(Date.parse(obj)); // UTC格式字符串转日期
    }

    return new Date(); // null, undefined, 0, '' 均返回当前时间
  }

  /**
   * [formatDate 格式化显示日期时间]
   * @param  {any} date [待显示的日期时间，可以为数字形式]
   * @param  {String} fmt [需要显示的格式，例如yyyy-MM-dd hh:mm:ss]
   * @return {String}   [格式化好的时间]
   */
  formatDate(date, fmt) {
    // 对时间进行时间戳处理成13位标准
    const tempTimestamp = parseInt(date, 10);
    if (tempTimestamp.toString().length === 10) {
      date = tempTimestamp * 1000;
    }
    date = this.toDate(date); // 保证date是日期类型(时间戳或UTC字符串转日期)
    if (!fmt) {
      // fmt = 'yyyy-MM-dd hh:mm:ss';
      fmt = 'yyyy.MM.dd';
    }
    const o = {
      y: date.getFullYear(),
      M: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      m: date.getMinutes(),
      s: date.getSeconds()
    };
    try {
      return fmt.replace(/(y+|M+|d+|h+|m+|s+)/g, function (item) {
        const len = item.length;
        return ((len > 1 ? '0' : '') + o[item.slice(-1)]).slice(-(len > 2 ? len : 2));
      });
    } catch (e) {
      console.error(e);
      return '';
    }
  }

  addString(str, number, addstring) {
    return str.toString().padStart(number, addstring);
  }
  /**
   * @function isSupportWebp 判断浏览器是否支持web格式
   * @return {[Boolean]}
   */
  isSupportWebp() {
    try {
      return (
        document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
      );
    } catch (err) {
      return false;
    }
  }

  /**
   * [numberToUnitStr 数字转换单位字符串]
   * @param  {[type]} number [description]
   * @return {[type]}        [description]
   *
   */
  numberToUnitStr(number) {
    if (!number) {
      number = 0;
    }
    number = parseInt(number, 10);

    // 数值显示规则（四舍五入，新规则）：

    if (number >= 1e10) {
      // 1百亿及以上 显示为xxx亿，如：123亿（12345678901）
      return parseInt(number / 1e8, 10) + '亿';
    } else if (number >= 1e8) {
      // 1亿到1百亿 显示成xx.x亿，如：12.3亿（1234567890）
      return (number / 1e8).toFixed(1) + '亿';
    } else if (number >= 1e6) {
      // 100万到1亿之间显示成xxx万，如：1234万（12345678）
      return parseInt(number / 1e4, 10) + '万';
    } else if (number >= 1e5) {
      // 10万到100万之间，显示成xx.x万，如：12.3万（123456
      return (number / 1e4).toFixed(1) + '万';
    } else if (number >= 1e4) {
      // 1万到10万之间，显示成xx.x万，如：12.3万（123456
      return (number / 1e4).toFixed(2) + '万';
    }

    // 10万以内直接显示 如：12345
    return number;
  }

  /**
   * @function chapterNameHanddle 处理章节名中的信息中的
   * @param {String} comicName 漫画名称
   * @param {String} chapterName 章节名称
   * @return {String} 返回处理后的章节名称
   */
  chapterNameHanddle(comicName, chapterName) {
    // 替换漫画名中的特殊字符
    const chapterReg = new RegExp(comicName.replace(/([+\\[\]()|])/gi, '\\$1'));
    // 将章节名中的漫画名称替换掉并去掉左右两边多余的空格
    let str = chapterName.replace(chapterReg, '').trim(),
      chapterNumber;

    // 删除替换后的章节名中的 "第"字
    // str = str.replace(/第/g, ''); // 以前老逻辑中直接删除掉了章节中所有的第字，存在误删
    str = str.replace(/第(\d+)/, '$1'); // 修复匹配到的第一个存在第加数字组合的替换掉第字

    // 如果字符传中存在：数字加话则提取出数字话
    let arrMatch = str.match(/\d+话/gi);
    if (arrMatch) {
      // 如果有数字小于10时 前面追加“0”
      let numMatch = str.match(/\d+/);
      if (numMatch) {
        chapterNumber = numMatch[0];

        // 前面有0不追加
        if (String(chapterNumber).indexOf('0') !== 0) {
          const tmpNum = Number(chapterNumber);
          if (tmpNum > 0 && tmpNum < 10) {
            chapterNumber = '0' + chapterNumber;
          }
        }
      }
    }

    // 删除字符串中多余的空格
    str = str.replace(/(\s)+/, '$1');
    const arrStr = str.split(' ');

    // 如果字符串分割后大于1且存在章节数则将第一个shift
    if (arrStr.length > 1 && chapterNumber) {
      arrStr.shift();
    }
    chapterName = arrStr.join(' ');
    return {
      chapterNumber,
      chapterName
    };
  }
  /**
   *
   *
   * @param {*} comicId 漫画id
   * @param {string} [ratio=''] 图片比例 300x400
   * @returns
   * @memberof Util
   */
  getComicCoverImg(comicId, ratio = '') {
    return `https://image.yqmh.com/mh/${comicId}_3_4${!ratio ? '' : `-${ratio}`}.jpg-noresize.webp`;
  }
  // 加密方法：
  // 1. get 请求
  // 使用请求的原始地址（去除域名后的目录开始到末尾去除'?'，不做排序，如：'http://comic.321mh.com/v2/comic/getcomicdata?comic_id=108192&young_mode=0&from_page=buy_history&productname=kmh&platformname=android', 则取此段： '/v2/comic/getcomicdatacomic_id=108192&young_mode=0&from_page=buy_history&productname=kmh&platformname=android'）
  // 2. post 请求
  // 将参数进行排序后拼接成使用'&'符号链接的字符串，连接请求目录，不进行解码，如：'{ animation_id: 2, video_id: 3, video_type: 1, view_type: "你好啊" }'，则处理成如下： '/v2/comic/getcomicdataanimation_id=2&video_id=3&video_type=1&view_type=你好啊'
  // 3. 加上盐值
  // 将拼好的字符串加上盐值，如：get: '/v2/comic/getcomicdatacomic_id=108192&young_mode=0&from_page=buy_history&productname=kmh&platformname=androiderciyuan2020', post: '/v2/comic/getcomicdataanimation_id=2&video_id=3&video_type=1&view_type=你好啊erciyuan2020', 这里的'erciyuan2020'是客户端服务端双方约定的盐值
  // 4. 将拼好后的字符串进行md5运算
  // 5. 将获得的md5字符串放入请求头的'm-request-id'字段进行请求
  getPathname(url) {
    const reg = /^http(s)?:\/\/(.*?)\//;
    return url.replace(reg, '');
  }

  getMD5(config, salt, MD5, qs) {
    let md5Str = `/${this.getPathname(config.url)}${qs.stringify(config.params)}${salt}`;
    return MD5(md5Str).toString();
  }

  postMD5(config, salt, MD5, qs) {
    const data = config.data;
    const sortParamsKeys = Object.keys(data).sort();
    const sortParams = Object.create(null);
    sortParamsKeys.forEach(key => (sortParams[key] = data[key]));
    let md5Str = `/${this.getPathname(config.url)}${qs.stringify(sortParams)}${salt}`;
    console.log(md5Str, 'md5Str');
    return MD5(md5Str).toString();
  }
  // 解密
  decrypt(word, CryptoJS) {
    const key = CryptoJS.enc.Utf8.parse('4548ded8c9e02690');
    const iv = CryptoJS.enc.Utf8.parse('1992360ee9bc4f8f');
    let encryptedHexStr = CryptoJS.enc.Base64.parse(word);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedStr);
  }
  lazyImg(className) {
    let observer = new IntersectionObserver(function (changes) {
      changes.forEach(function (item) {
        if (item.intersectionRatio > 0 && item.intersectionRatio <= 1) {
          // target：被观察的目标元素，是一个 DOM 节点对象
          item.target.src = item.target.dataset.src;
          // 解除观察
          observer.unobserve(item.target);
        }
      });
    });
    let listItems = document.querySelectorAll(className);
    listItems.forEach(function (item) {
      // 实例的observe方法可以指定观察哪个DOM节点
      // 开始观察 observe的参数是一个 DOM 节点对象
      observer.observe(item);
    });
  }
}

export default new Util();
