<template>
  <div class="nav-bar">
    <ul class="nav-list">
      <router-link class="nav-list-item active" to="/home">
        <img class="van-tab nav-tab1" :src="icon_tab_home1" />
        <span>首页</span>
      </router-link>
      <router-link class="nav-box nav-list-item" to="/announcement">
        <!-- <van-badge dot> -->
        <!-- <div class=""> -->

        <div class="dot" v-if="showPoint === 1"></div>
        <img class="van-tab nav-tab3" :src="icon_tab_gg1" />
        <span>公告</span>
        <!-- </div> -->
        <!-- </van-badge> -->
      </router-link>

      <router-link class="nav-box nav-list-item" to="/user">
        <div class="dot" v-if="showSendPoint == 1 || showWishPoint"></div>
        <img class="van-tab nav-tab2" :src="icon_tab_mine1" />
        <span>我的</span>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { getnoticereadstatus } from '@/service/home';
import { nftUtils } from '@/utils';
import { useStore } from 'vuex';
// import { useRoute } from 'vue-router';
// import { useStore } from 'vuex';
// import { getLocal } from '@/common/js/utils';
export default {
  setup() {
    const store = useStore();
    const state = reactive({
      isLogin: false, // 是否已登录
      showPoint: 0,
      showSendPoint: 0,
      showWishPoint: false
    });
    // const route = useRoute();
    // const store = useStore();
    onMounted(async () => {
      state.isLogin = nftUtils.isLogin();
      console.log(111);
      if (state.isLogin && store.state.ggPoint == 0) {
        await store.dispatch('getnoticereadstatus');
        state.showPoint = store.state.ggPoint;
        // const { data } = await getnoticereadstatus();
        // state.showPoint = data;
      } else {
        state.showPoint = store.state.ggPoint;
      }

      if (state.isLogin && store.state.sendPoint == 0) {
        await store.dispatch('gethotdot');
        state.showSendPoint = store.state.sendPoint;
        // const { data } = await getnoticereadstatus();
        // state.showPoint = data;
      } else {
        state.showSendPoint = store.state.sendPoint;
      }

      // 许愿红点
      if (state.isLogin && store.state.wishPoint == false) {
        await store.dispatch('getisshowredmark');
        state.showWishPoint = store.state.wishPoint;
      } else {
        state.showWishPoint = store.state.wishPoint;
      }

      // const token = getLocal('token');
      // const path = route.path;
      // if (token && !['/home', '/category'].includes(path)) {
      //   store.dispatch('updateCart');
      // }
    });
    // const count = computed(() => {
    //   return store.state.cartCount;
    // });
    const count = 1;

    return {
      ...toRefs(state),
      count,

      icon_tab_mine: require('../imgs/icon/icon_tab_mine.png'),
      icon_tab_mine1: require('../imgs/icon/icon_tab_mine1.png'),
      icon_tab_home: require('../imgs/icon/icon_tab_home.png'),
      icon_tab_home1: require('../imgs/icon/icon_tab_home1.png'),
      icon_tab_gg: require('../imgs/icon/icon_tab_gg.png'),
      icon_tab_gg1: require('../imgs/icon/icon_tab_gg_gre.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.nav-box {
  position: relative;
  .dot {
    width: 6px;
    height: 6px;
    background: #e63f3f;
    opacity: 1;
    border-radius: 50%;
    position: absolute;
    right: 42px;
    top: 10px;
  }
}
.nav-bar {
  background-color: $primaryc;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 76px;
  z-index: 998;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  .nav-list {
    width: 100%;
    @include fj();
    flex-direction: row;
    padding: 0;
    .nav-list-item {
      display: flex;
      flex: 1;

      flex-direction: column;
      text-align: center;
      align-items: center;
      color: #666;
      padding-top: 7px;

      .van-tab {
        width: 36px;
        max-height: 30px;
        height: 36px;
      }

      &.router-link-active {
        color: $fc;
        .nav-tab1 {
          content: url('../imgs/icon/icon_tab_home.png');
          width: 36px;

          height: 36px;
        }
        .nav-tab2 {
          content: url('../imgs/icon/icon_tab_mine.png');
          width: 36px;
          height: 36px;
        }
        .nav-tab3 {
          content: url('../imgs/icon/icon_tab_gg.png');
          width: 36px;
          height: 36px;
        }
      }
      span {
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
}
</style>
