/**
 * 浏览器特性、请求平台特性
 */

import urlUtil from './url'; // 对url参数的处理
import conf from '../constants'; // 平台基本信息

class Client {
  constructor() {}

  getClientInfo() {
    const RULES = {
      // ANDROID: /(^| |\()(android)(;| |\/|$)/i,
      ANDROID: /Android/i,
      IOS: /iPhone|iPad|iPod/i,
      // IOS: /(^| |\()(iPhone|iPad|iPod)(;| |\/|$)/i,
      WEIXIN: /MicroMessenger/i,
      QQ: /(^| )(QQ)( |\/|$)/i,
      QQBROWSER_HD: /iPad.*?(^| )(MQQBrowser)( |\/|$)/i,
      QQBROWSER: /(^| )(MQQBrowser)( |\/|$)/i,
      IPAD: /iPad/,
      VERSION: /[a-zA-Z]{3,4}(_android|_iphone|_ipad),(\d.\d{1,2}.\d{1,2})/i,
      HUAWEI: /HUAWEI/i,
      AQIYI: /IqiyiApp/i,
      CLIENT_CHANNEL: /[a-zA-Z]{3,4}(_android|_iphone|_ipad),(\d.\d{1,2}.\d{1,2}),(\w+)/i
    };
    // 判断产品线名 kmh mht smh aym asmh
    // * 知音漫客 zymk
    // * 看漫 kmh
    // * 爱优漫 aym
    // * 漫画台 mht
    // * 神漫画 smh
    // * 爱飒漫画 asmh
    // * 漫客栈 mkzhan 因为漫客栈UA中的没有mkzhan所以使用：安卓app_id=104，isoapp_id=103
    // * 全免漫画 qmmh
    let conf = { reg: /zymk|kmh|mht|aym|smh|asmh|IqiyiApp|(app_id=104)|(app_id=103)|qmmh|kaimh/i };
    let UA = window.navigator.userAgent;
    let isIOS = RULES.IOS.test(UA);
    let isAndroid = RULES.ANDROID.test(UA);
    let isQQ = RULES.QQ.test(UA);
    let isWX = RULES.WEIXIN.test(UA);
    let isIPAD = RULES.IPAD.test(UA);
    let isHUAWEI = RULES.HUAWEI.test(UA);
    let isAqiyi = RULES.AQIYI.test(UA);
    // @ts-ignore
    let os = isAndroid
      ? UA.match(RULES.ANDROID)[0].toLowerCase()
      : isIOS
      ? UA.match(RULES.IOS)[0].toLowerCase()
      : 'android';
    let isApp = false;
    if (conf) {
      let app = UA.match(conf.reg);
      if (app) {
        isApp = true;
      }
    }
    // 获取url产品线
    const pName = this.getPlatformName();
    let _deviceName = UA.match(RULES.CLIENT_CHANNEL);
    const deviceName = _deviceName ? _deviceName[3] : '';
    let _version = UA.match(RULES.VERSION);
    let version = _version ? _version[2] : '0';
    let info = {
      os,
      isApp,
      isIOS,
      isAndroid,
      isQQ,
      isWX,
      isIPAD,
      pName,
      version,
      isHUAWEI,
      isAqiyi,
      deviceName
    };
    return info;
  }

  // 平台配置
  getPlatformName() {
    const UA = window.navigator.userAgent;
    let pName = urlUtil.parseQuery().pName || 'kmh';
    let conf = {
      reg: /zymk|kmh|mht|aym|smh|asmh|IqiyiApp|(app_id=104)|(app_id=103)|qmmh|kaimh/i
    };
    let app = UA.match(conf.reg);
    if (app) {
      pName = app[0].toLowerCase();
    }
    return pName;
  }

  // 分平台基础配置
  getPlatformConfig(v) {
    const pName = v ? v : this.getPlatformName();
    return conf.app[pName];
  }
  /**
   * @name: validateActivitySupport
   * @description: 检查该环境是否支持当前活动
   * @return {boolean}
   */
  validateActivitySupport() {
    const { isIOS, isAndroid } = this.getClientInfo();
    const { sn } = this.getPlatformConfig();
    const { android = [], ios = [] } = conf.supportPlatform;
    if (isAndroid) return android.includes(sn);
    if (isIOS) return ios.includes(sn);
  }
}

export default new Client();
