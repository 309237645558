/**
 * js加载器
 */

class JsLoader {
  constructor() {}

  /**
   * loadjs 同步或异步方式加载js文件 opt:
   * @param  {string} src 加载js地址
   * @param  {[type]} options
   * 可配置参数如下：默认同步输出
   * {
   *  charset: 'utf-8',
   *  id: '',
   *  data: '',
   *  async: true
   * }
   */
  loadjs(src, options) {
    options = options || {};
    if (options.async) {
      const obj = document.createElement('script');

      obj.src = src;
      obj.async = true;
      obj.type = 'text/javascript';
      if (options.charset) {
        obj.charset = options.charset;
      }
      if (options.data) {
        obj.data = options.data;
      }
      if (options.id) {
        obj.id = options.id;
      }
      (
        document.head ||
        document.getElementsByTagName('head')[0] ||
        document.docElement
      ).appendChild(obj);
    } else {
      document.write(
        `<script src="${src}"${options.charset ? ` charset="${options.charset}"` : ''}${
          options.data ? ` data-key="${options.data}"` : ''
        }${options.id ? ` id="${options.id}"` : ''}></script>`
      );
    }
  }
}

export default new JsLoader();
