import { getUserData } from '@/service/user';
import {
  homeList,
  getnoticereadstatus,
  historylist,
  gethotdot,
  getisshowredmark
} from '@/service/home';
import { localStorageUtil } from '@/utils';
export default {
  async refreshLoginInfo(ctx) {
    const { data, server_time } = await getUserData();
    if (data && data.access_token) {
      localStorageUtil.set('accessToken', data.access_token);
      localStorageUtil.set('server_time', server_time);
      ctx.commit('loginInfo', {
        loginInfo: data
      });
    }
  },
  async getHomeList(ctx) {
    const { data, server_time } = await homeList({ time: new Date().getTime() });
    localStorageUtil.set('server_time', server_time);
    ctx.commit('homeList', {
      homeList: data
    });
  },
  async getHomeHistoryList(ctx) {
    const { data } = await historylist();
    ctx.commit('homehistoryList', {
      data
    });
  },
  async getnoticereadstatus(ctx) {
    const { data } = await getnoticereadstatus();
    ctx.commit('changeGgTabPoint', {
      data: data
    });
  },

  async gethotdot(ctx) {
    const { data } = await gethotdot();
    ctx.commit('changeSendPoint', {
      data
    });
  },

  async getisshowredmark(ctx) {
    const { data } = await getisshowredmark();
    ctx.commit('changeWishPoint', {
      data
    });
  }
};
