import VueProgressBar from '@aacassandra/vue3-progressbar';
import Cookie from 'vue-cookie';
import lazyPlugin from 'vue3-lazy';
import Toast from '@/components/toast/toast';
import Loading from '@/components/loading/loading';

const installPlugins = app => {
  app
    .use(Toast)
    .use(Loading)
    .use(VueProgressBar, { color: '#0AC416', failedColor: 'red', height: '2px' })
    .use(lazyPlugin, {
      error: require('../imgs/comm/placeholder.png')
    });
  app.provide('$cookie', Cookie);
  app.config.globalProperties.$cookie = Cookie;
};

export default installPlugins;
