const wxRoutes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      index: 1,
      title: '看漫数字藏品商城',
      showNav: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      index: 2,
      title: '登录注册'
    }
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('@/views/Authentication.vue'),
    meta: {
      index: 2,
      title: '实名认证',
      needLogin: true
    }
  }
];

export default wxRoutes;
