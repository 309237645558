export default {
  loginInfo: {},
  homeList: [],
  homehistoryList: [],
  showPrepaidHome: false,
  posterUrl: undefined,
  ggPoint: 0, // 是否tab显示公告红点
  commonPosterUrl: undefined,
  sendPoint: 0,
  pointData: {},
  webBox: false, //顶部悬浮条
  wishPoint:false
};
