/**
 * nft相关方法
 *
 */

import { util, localStorageUtil, clientUtil, urlUtil } from '@/utils';

import Scheme from '@acgn/flyer/src/scheme/index';
const mobileReg = /^([1]\d{10}|([(（]?0[0-9]{2,3}[）)]?[-]?)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?)$/;

let { isApp, isWx, isIOS, isAndroid, pName, version } = clientUtil.getClientInfo();

const schemeUtil = new Scheme({ modules: { clientUtil, urlUtil } });
class NftUtils {
  constructor() {}
  /**
   * 页面切换埋点
   */
  setcnzzRecordByPage(title) {
    util.setcnzzRecord('数字藏品'+localStorageUtil.get('utm_source') || '', '页面切换', title);
  }
  /**
   * 按钮点击埋点
   */
  setcnzzRecordByBtn(title) {
    util.setcnzzRecord('数字藏品'+localStorageUtil.get('utm_source') || '', '按钮点击', title);
  }
  /**
   * 验证码重发倒计时
   */
  countDownFn(state, count = 60) {
    state.disabled = true;
    state.isCountDown = true;
    state.primary = 'default';
    state.text = `${count}秒后重发`;
    let timer = setInterval(() => {
      count--;
      if (count < 0) {
        count = 60;
        clearInterval(timer);
        state.disabled = false;
        state.text = '重获验证码';
        state.primary = 'primary';
        state.isCountDown = false;
      } else {
        state.text = `${count}秒后重发`;
      }
    }, 1000);
  }

  mobileTest(tel) {
    return mobileReg.test(tel);
  }
  /**
   * 是否登录
   */
  isLogin() {
    return !!localStorageUtil.get('accessToken');
  }

  getIdNumberAge(cardNum) {
    let age = 18;
    if (cardNum.length == 18) {
      //获取年龄

      const myDate = new Date();

      const month = myDate.getMonth() + 1;

      const day = myDate.getDate();

      age = myDate.getFullYear() - cardNum.substring(6, 10) - 1;

      if (
        cardNum.substring(10, 12) < month ||
        (cardNum.substring(10, 12) == month && cardNum.substring(12, 14) <= day)
      ) {
        age++;
      }
    }
    return age;
  }

  getNoFullMobile(mobile) {
    if (mobile.length > 8) {
      return mobile.replace(mobile.substring(3, 7), '****');
    }
    return mobile;
  }

  isInApp() {
    //判断是否在APP内
    return isApp;
  }

  isInWx() {
    return isWx;
  }

  isAndroid() {
    return isAndroid;
  }

  isIOS() {
    return isIOS;
  }
  /**
   *  发送自定义协议
   */
  appScheme(url) {
    let platformInfo = schemeUtil.platformInfo();
    const scheme_Adr = platformInfo.scheme_Adr;
    let path = `${scheme_Adr}://${url}`;
    schemeUtil.createIframe(path, {
      id: 'toApp'
    });
  }
  /**
   * 禁止下拉
   */
  noPull() {
    if (this.isInApp()) {
      this.appScheme('app?callback=nopull');
    }
  }
  /**
   * 禁止左滑退出页面
   */
  noLeftBack() {
    if (this.isInApp()) {
      this.appScheme('app?callback=noleftback');
    }
  }
  /**
   * 是否支持APP内的协议分享
   */
  isSupprotNftShare() {
    return (
      ['kmh'].includes(pName) &&
      ((isAndroid && version >= '4.0.5') || (isIOS && version >= '2.0.1'))
    );
  }
  /**
   * App内分享文字
   */
  nftShareText(content) {
    if (!this.isSupprotNftShare()) {
      return;
    }
    const urlencoded = encodeURIComponent(content);
    this.appScheme(`customshare?content=${urlencoded}&type=1`);
  }
  /**
   * App内分享图片
   */
  nftSharePic(picUrl) {
    if (!this.isSupprotNftShare()) {
      return;
    }
    const urlencoded = encodeURIComponent(picUrl);
    this.appScheme(`customshare?content=${urlencoded}&type=2`);
  }

  shareDialog(shareConf) {
    var title = shareConf.title;
    if (title) {
      title = encodeURIComponent(title);
    }
    var content = shareConf.content;
    if (content) {
      content = encodeURIComponent(content);
    }
    var image = shareConf.image;
    if (image) {
      image = encodeURIComponent(image);
    }
    var link = shareConf.link;
    if (link) {
      link = encodeURIComponent(link);
    }
    this.appScheme(
      `share?title=${title}&content=${content}&image=${image}&link=${link}&callback=shareCallback`
    );
  }

  // 等级icon
  levelsChange(level){
    let src = '';
    //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
    switch (level) {
      case 1:
        src = require('../imgs/comm/icon_label_ordinary.png');
        break;
      case 2:
        src = require('../imgs/comm/icon_label_rare.png');
        break;
      case 3:
        src = require('../imgs/comm/icon_label_epic.png');
        break;
      case 4:
        src = require('../imgs/comm/icon_label_legend.png');
        break;
      case 6:
        src = require('../imgs/comm/icon_label_cs.png');
        break;
      case 5:
        src = require('../imgs/comm/icon_label_sh.png');
        break;
    }
    return src;
  }
}

export default new NftUtils();
