import axios from 'axios';
// import app from '../main';
import { setTime } from './util';
axios.defaults.baseURL = '/api';
axios.defaults.timeout = 10000;
import { localStorageUtil } from '@/utils';
// const appProgress = app.config.globalProperties.$Progress;

/**
 * 在请求或响应被 then 或 catch 处理前拦截它们。
 * 添加请求拦截器
 * */
axios.interceptors.request.use(
  function (config) {
    // appProgress.start();
    // 配置活动信息
    config.headers.authorization = config['requestToken'] || window.configs.requestToken;
    config.headers['access-token'] =
      config['accessToken'] || localStorage.getItem('accessToken') || '';
    if (config.method === 'get') {
      // setProductInfo(config.params);
      if (config.noCache || config.cache === false) {
        setTime(config.params);
      }
    } else {
      // setProductInfo(config.data);
    }

    return config;
  },
  function (error) {
    // appProgress.fail();
    return Promise.reject(error);
  }
);

/**
 * 添加响应拦截器
 * */
axios.interceptors.response.use(
  function (response) {
    // appProgress.finish();
    // 登陆过期，跳转登陆

    return response.data;
  },
  function (error) {
    if (error.response.status == 401) {
      localStorageUtil.del('accessToken');
      window.location.href = window.location.origin + '/login';
    }
    // appProgress.fail();
    return Promise.reject(error);
  }
);

const get = function (url, data, conf = {}) {
  return axios.get(url, { params: data, ...conf }).catch(function (thrown) {
    console.log(thrown);
    if (axios.isCancel(thrown)) {
      console.log('Request canceled');
    } else {
      // handle error
    }
  });
};

const post = (url, data, conf = {}) => {
  return axios.post(url, data, conf).catch(thrown => {
    if (axios.isCancel(thrown)) {
      console.log('Request canceled');
    } else {
      // handle error
    }
  });
};
const put = (url, data, conf = {}) => {
  return axios.put(url, data, conf).catch(thrown => {
    if (axios.isCancel(thrown)) {
      console.log('Request canceled');
    } else {
      // handle error
    }
  });
};
export default {
  get,
  post,
  put
};
