import { http } from '@/api';

/**
 * 获取用户信息
 * @param {*} params
 * @returns
 */
export function getUserData() {
  return http.get('/v1/user/refreshtoken');
}

/**
 * 登录
 * @param {*} params
 * @returns
 */
export function login(params) {
  return http.post('/v1/login', params);
}
/**
 * 修改手机号
 * @param {*} params
 * @returns
 */
export function setMobile(params) {
  return http.post('/v1/user/setmobile', params);
}
/**
 * 获取用户详情
 * @param {*} params
 * @returns
 */
export function getUserCollection(params) {
  return http.get('/v1/user/getuserinfo', params);
}

/**
 * 发送短信验证码
 * @param {*} params
 * @returns
 */
export function sendSms(params) {
  return http.post('/v1/sms/sendsms', params);
}

/**
 * 取消转赠
 * @param {*} params
 * @returns
 */
export function cancelCollection(params) {
  return http.post('/v2/contribute/cancelcollection', params);
}

/**
 * 接受转赠
 * @param {*} params
 * @returns
 */
export function acceptCollection(params) {
  return http.post('/v2/contribute/acceptcollection', params);
}

/**
 * 获取我的转赠/收到赠与列表
 * @param {*} params
 * @returns
 */
export function contributeList(params) {
  return http.get('/v2/contribute/contributelist', params);
}

/**
 * 转赠前实名验证接口
 * @param {*} params
 * @returns
 */
export function contributeCertification(params) {
  return http.get('/v2/contribute/certification', params);
}

/**
 * 转赠操作接口
 * @param {*} params
 * @returns
 */
export function makeCollection(params) {
  return http.post('/v2/contribute/makecollection', params);
}

/**
 * 实名认证
 * @param {*} params
 * @returns
 */
export function authMetaSimple(params) {
  return http.post('/v1/user/mobile3metasimple', params);
}

// 获取用户藏品
export function getusercollections(params) {
  return http.get('/v1/user/getusercollections', params);
}

// 用户藏品(折叠)
export function getusercollectionsfold(params) {
  return http.get('/v1/user/getusercollectionsfold', params);
}

// 接受转赠操作接口
export function acceptbyprice(params) {
  return http.post('/v1/pay/acceptbyprice', params);
}

// 获取杉德云账户跳转
export function sandpayCloud(params) {
  return http.get('/v1/sandpay_cloud', params);
}

// 获取转赠前发的短信数量
export function isonesendsms(params) {
  return http.get('/v2/contribute/isonesendsms', params);
}

// 获取当前用户是否在白名单中
export function getwhiteuser(params) {
  return http.get('/v1/white/getwhiteuser', params);
}

// 获取杉德云账户余额
export function sandpay_balance(params) {
  return http.get('/v1/sandpay_balance', params);
}

// 新增关联信息
export function addscanlog(params) {
  return http.post('/v1/user/addinvitev2', params);
}
