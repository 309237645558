import { createRouter, createWebHistory } from 'vue-router';
import wxRoutes from './wxRoutes.js';
import { nftUtils } from '@/utils';
// import VConsole from 'vconsole';
// const NODE_ENV = process.env.NODE_ENV || 'production';
// if (NODE_ENV !== 'production') {
//   new VConsole();
// }
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      index: 1,
      title: '看漫数字藏品商城',
      showNav: true
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/User.vue'),
    meta: {
      index: 1,
      title: '我的',
      showNav: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      index: 2,
      title: '登录注册',
      showNav: true
    }
  },
  {
    path: '/set',
    name: 'set',
    component: () => import('@/views/Setting.vue'),
    meta: {
      index: 2,
      title: '设置',
      needLogin: true,
      showNav: true
    }
  },
  {
    path: '/modifyphone',
    name: 'modifyphone',
    component: () => import('@/views/ModifyPhone.vue'),
    meta: {
      index: 2,
      title: '修改手机号',
      needLogin: true,
      showNav: true
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/Order.vue'),
    meta: {
      index: 2,
      title: '订单',
      needLogin: true,
      showNav: true
    }
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('@/views/Collections.vue'),
    meta: {
      index: 2,
      title: '我的藏品',
      needLogin: true,
      showNav: true
    }
  },
  {
    path: '/giftmanage',
    name: 'giftmanage',
    component: () => import('@/views/GiftManage.vue'),
    meta: {
      index: 2,
      title: '赠予管理',
      needLogin: true,
      showNav: true
    }
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('@/views/Authentication.vue'),
    meta: {
      index: 2,
      title: '实名认证',
      needLogin: true,
      showNav: true
    }
  },
  {
    path: '/authsuccess',
    name: 'authsuccess',
    component: () => import('@/views/AuthSuccess.vue'),
    meta: {
      index: 2,
      title: '实名认证成功',
      needLogin: true,
      showNav: true
    }
  },
  {
    path: '/blindbox/:id',
    name: 'blindbox',
    component: () => import('@/views/BlindBoxDetail.vue'),
    meta: {
      index: 3,
      title: '盲盒详情',
      showNav: true
    }
  },
  {
    path: '/product/:id',
    name: 'product',
    component: () => import('@/views/ProductDetail.vue'),
    meta: {
      index: 4,
      title: '卡片详情',
      showNav: true
    }
  },
  {
    path: '/boxopen/:id',
    name: 'boxopen',
    component: () => import('@/views/BoxOpen.vue'),
    meta: {
      index: 5,
      title: '开启盲盒'
    }
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('@/views/InviteAward.vue'),
    meta: {
      index: 2,
      title: '邀请有奖',
      needLogin: true
    }
  },
  {
    path: '/inviteRecord',
    name: 'inviteRecord',
    component: () => import('@/views/InviteRecord.vue'),
    meta: {
      index: 2,
      title: '邀请记录',
      needLogin: true
    }
  },
  {
    path: '/privilege',
    name: 'privilege',
    component: () => import('@/views/privilege.vue'),
    meta: {
      index: 2,
      title: '我的特权'
      //  needLogin: true
    }
  },
  {
    path: '/historyActivity',
    name: 'historyActivity',
    component: () => import('@/views/historyActivity.vue'),
    meta: {
      index: 2,
      title: '历史活动',
      needLogin: true
    }
  },
  {
    path: '/winnerList',
    name: 'winnerList',
    component: () => import('@/views/winnerList.vue'),
    meta: {
      index: 2,
      title: '获奖名单',
      needLogin: true
    }
  },
  // 邀请有奖二期-新开页面（瓜分10万现金奖励）
  {
    path: '/inviteActivity',
    name: 'inviteActivity',
    component: () => import('@/views/inviteActivity.vue'),
    meta: {
      index: 2,
      title: '邀请有奖',
      needLogin: false
    }
  },
  {
    path: '/downloadApp',
    name: 'downloadApp',
    component: () => import('@/views/downloadApp.vue'),
    meta: {
      index: 2,
      title: '下载APP',
      needLogin: true
    }
  },
  {
    path: '/tonglianPay',
    name: 'tonglianPay',
    component: () => import('@/views/TonglianPay.vue'),
    meta: {
      index: 2,
      title: '通联支付/充值'
    }
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/Wallet/index.vue'),
    meta: {
      index: 2,
      title: '钱包'
    }
  },
  {
    path: '/awardList',
    name: 'awardList',
    component: () => import('@/views/awardList.vue'),
    meta: {
      index: 2,
      title: '获奖名单'
    }
  },
  {
    path: '/announcement',
    name: 'announcement',
    component: () => import('@/views/Announcement/index.vue'),
    meta: {
      index: 1,
      title: '公告',
      showNav: true
    }
  },
  {
    path: '/announcement/detail',
    name: 'ggdetail',
    component: () => import('@/views/Announcement/Detail.vue'),
    meta: {
      index: 2,
      title: '公告详情'
      // showNav: true
    }
  },
  {
    path: '/invite/invite3',
    name: 'invite3',
    component: () => import('@/views/invite/invite3.vue'),
    meta: {
      index: 2,
      title: '邀请有奖'
    }
  },
  {
    path: '/compound',
    name: 'Compound',
    component: () => import('@/views/Compound.vue'),
    meta: {
      index: 2,
      title: '合成藏品',
      showNav: true
    }
  },
  {
    path: '/CollectionsMeth',
    name: 'CollectionsMeth',
    component: () => import('@/views/CollectionsMeth.vue'),
    meta: {
      index: 2,
      title: '分解藏品'
    }
  },
  {
    path: '/myPoint',
    name: 'myPoint',
    component: () => import('@/views/myPoint.vue'),
    meta: {
      index: 2,
      title: '我的积分',
      needLogin: true,
      showNav: true
    }
  },
  {
    path: '/integralDetail',
    name: 'integralDetail',
    component: () => import('@/views/integralDetail.vue'),
    meta: {
      index: 2,
      title: '我的积分'
    }
  },
  {
    path: '/collectionsDetail',
    name: 'collectionsDetail',
    component: () => import('@/views/collectionsDetail.vue'),
    meta: {
      index: 2,
      title: '藏品详情',
      showNav: true
    }
  },
  {
    path: '/collectionSend',
    name: 'collectionSend',
    component: () => import('@/views/collectionSend.vue'),
    meta: {
      index: 2,
      title: '藏品转赠'
    }
  },
  {
    path: '/myHb',
    name: 'myHb',
    component: () => import('@/views/myHb.vue'),
    meta: {
      index: 2,
      title: '我的红包',
      showNav: true
    }
  },
  {
    path: '/synthetic/compound',
    name: 'Compound2',
    component: () => import('@/views/Synthetic/Compound.vue'),
    meta: {
      index: 2,
      title: '合成藏品',
      showNav: true
    }
  },
  {
    path: '/Learning/retreat',
    name: 'retreat',
    component: () => import('@/views/Learning/retreat.vue'),
    meta: {
      index: 2,
      title: '闭关修炼'
    }
  },
  {
    path: '/Learning/send',
    name: 'send',
    component: () => import('@/views/Learning/send.vue'),
    meta: {
      index: 2,
      title: '派遣修炼'
    }
  },
  {
    path: '/Learning/stop',
    name: 'stop',
    component: () => import('@/views/Learning/stop.vue'),
    meta: {
      index: 2,
      title: '终止修炼'
    }
  },
  {
    path: '/invite/invite4',
    name: 'invite4',
    component: () => import('@/views/invite/invite4.vue'),
    meta: {
      index: 2,
      title: '邀请有奖'
    }
  },
  {
    path: '/synthetic/sevenDays',
    name: 'sevenDays',
    component: () => import('@/views/Synthetic/sevenDays.vue'),
    meta: {
      index: 2,
      title: '合成藏品'
    }
  },
  {
    path: '/helpPage',
    name: 'helpPage',
    component: () => import('@/views/helpPage.vue'),
    meta: {
      index: 2,
      title: '邀请助力'
    }
  },
  {
    path: '/synthetic/ztCompound',
    name: 'ztCompound',
    component: () => import('@/views/Synthetic/ztCompound.vue'),
    meta: {
      index: 2,
      title: '合成藏品'
    }
  },
  {
    path: '/invite/invite5',
    name: 'invite5',
    component: () => import('@/views/invite/invite5.vue'),
    meta: {
      index: 2,
      title: '邀请有奖',
      showNav: true
    }
  },
  {
    path: '/invite/invite6',
    name: 'invite6',
    component: () => import('@/views/invite/invite6.vue'),
    meta: {
      index: 2,
      title: '邀请有奖',
      showNav: true
    }
  },
  {
    path: '/invite/updatedImage',
    name: 'updatedImage',
    component: () => import('@/views/invite/updatedImage.vue'),
    meta: {
      index: 2,
      title: '上传截图'
    }
  },

  {
    path: '/synthetic/lcCompound',
    name: 'lcCompound',
    component: () => import('@/views/Synthetic/lcCompound.vue'),
    meta: {
      index: 2,
      title: '合成藏品',
      showNav: true
    }
  },
  {
    path: '/wishWell',
    name: 'wishWell',
    component: () => import('@/views/wishWell.vue'),
    meta: {
      index: 2,
      title: '许愿池',
      needLogin: true,
      showNav: true
    }
  },
  {
    path: '/wishRecord',
    name: 'wishRecord',
    component: () => import('@/views/wishRecord.vue'),
    meta: {
      index: 2,
      title: '达成记录',
      showNav: false
    }
  },
  {
    path: '/synthetic/lxgCompound',
    name: 'lxgCompound',
    component: () => import('@/views/Synthetic/lxgCompound.vue'),
    meta: {
      index: 2,
      title: '合成藏品',
      showNav: true
    }
  },
  {
    path: '/synthetic/wzCompound',
    name: 'wzCompound',
    component: () => import('@/views/Synthetic/wzCompound.vue'),
    meta: {
      index: 2,
      title: '合成藏品',
      showNav: true
    }
  },
  {
    path: '/synthetic/pttqCompound',
    name: 'pttqCompound',
    component: () => import('@/views/Synthetic/pttqCompound.vue'),
    meta: {
      index: 2,
      title: '合成藏品',
      showNav: true
    }
  },
  {
    path: '/nationalSign',
    name: 'nationalSign',
    component: () => import('@/views/nationalSign.vue'),
    meta: {
      index: 2,
      title: '国庆签到'
    }
  },
  {
    path: '/synthetic/basicCompound',
    name: 'basicCompound',
    component: () => import('@/views/Synthetic/basicCompound.vue'),
    meta: {
      index: 2,
      title: '合成藏品',
      showNav: true
    }
  },
  {
    path: '/wishSharePage',
    name: 'wishSharePage',
    component: () => import('@/views/wishSharePage.vue'),
    meta: {
      index: 1,
      title: '分享心愿'
    }
  },
  {
    path: '/invite/collectActivity',
    name: 'collectActivity',
    component: () => import('@/views/invite/collectActivity.vue'),
    meta: {
      index: 2,
      title: '收藏活动',
      showNav: true
    }
  },
  {
    path: '/Learning/sendGroup',
    name: 'sendGroup',
    component: () => import('@/views/Learning/sendGroup.vue'),
    meta: {
      index: 2,
      title: '组合修炼'
    }
  },
  {
    path: '/Learning/stopGroup',
    name: 'stopGroup',
    component: () => import('@/views/Learning/stopGroup.vue'),
    meta: {
      index: 2,
      title: '停止修炼'
    }
  },
  {
    path: '/invite/saleUpload',
    name: 'saleUpload',
    component: () => import('@/views/invite/saleUpload.vue'),
    meta: {
      index: 1,
      title: '上传截图',
      showNav: true
    }
  },
  {
    path: '/invite/saleAward',
    name: 'saleAward',
    component: () => import('@/views/invite/saleAward.vue'),
    meta: {
      index: 1,
      title: '抽奖结果',
      needLogin: true
    }
  },
  {
    path: '/invite/saleInviteLogs',
    name: 'saleInviteLogs',
    component: () => import('@/views/invite/saleInviteLogs.vue'),
    meta: {
      index: 1,
      title: '邀请分佣',
      showNav: true
    }
  },
  {
    path: '/compoundList',
    name: 'compoundList',
    component: () => import('@/views/compoundList.vue'),
    meta: {
      index: 1,
      title: '合成列表',
      showNav: true
    }
  },
  {
    path: '/footballDraw/index',
    name: 'footballDraw',
    component: () => import('@/views/footballDraw/index.vue'),
    meta: {
      index: 1,
      title: '足球抽奖'
    }
  },
  {
    path: '/getCollect/yfPage',
    name: 'yfPage',
    component: () => import('@/views/getCollect/yfPage.vue'),
    meta: {
      index: 1,
      title: '领取叶凡'
    }
  },
  {
    path: '/Synthetic/collectCompound',
    name: 'collectCompound',
    component: () => import('@/views/Synthetic/collectCompound.vue'),
    meta: {
      index: 1,
      title: '藏品合成'
    }
  }
];

const router = createRouter({
  history: createWebHistory(''),
  routes: nftUtils.isInWx() ? wxRoutes : routes
});

export default router;
