import { createApp } from 'vue';
import ToastComponent from './index';
import { domUtil } from '@/utils';

const Toast = {};
Toast.install = function (app) {
  const className = '__app-toast__';
  const instance = domUtil.createComponentInstanceDom(createApp, ToastComponent, className);

  const toastFn = (opt = {}) => {
    clearTimeout(instance.toastConf.timer);
    Object.assign(instance.toastConf, { className: '' }, opt);
    instance.toastConf.show = true;
    instance.toastConf.timer = setTimeout(() => {
      instance.toastConf.show = false;
      instance.toastConf.callback && instance.toastConf.callback();
    }, instance.toastConf.duration);
  };

  app.config.globalProperties.$toast = toastFn;
  app.provide('$toast', toastFn);
};

export default Toast;
