import { clientUtil } from '../utils';

/**
 * 设置请求平台信息
 * @param {objetc} 请求参数
 */
function setProductInfo(e) {
  const clientInfo = clientUtil.getClientInfo();
  e = e || {};
  e.productname = e.productname || clientInfo.pName || clientInfo.isApp; // 获取产品线信息
  e.platformname = clientInfo.os || 'android';
  return e;
}
/**
 * 设置get请求 添加随机数
 * @param {objetc} 请求参数
 */
const setTime = (e = {}) => {
  e._v = new Date().getTime();
  return e;
};
export { setProductInfo, setTime };
