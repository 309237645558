/**
 * 分享相关
 * 调起app分享|调起app分享图片带二维码|配置app分享字段|app分享回调函数
 */

import conf from '../constants'; // 平台基本信息
import stringUtil from './string'; // 字符串处理
import clientUtil from './client';
import urlUtil from './url';
import Scheme from '@acgn/flyer/src/scheme/index';
const schemeUtil = new Scheme({ modules: { clientUtil, urlUtil } });
class Share {
  constructor() {}

  /**
   * 调起app分享（手动触发分享，如页面按钮点击）
   * @param shareConf link 链接encode title content image
   */
  shareByApp(shareConf) {
    const platformConfig = clientUtil.getPlatformConfig();
    let _conf = Object.assign(
      {
        callback: 'shareCallback',
        image: encodeURIComponent(platformConfig.image),
        _v: +new Date()
      },
      shareConf
    );
    let path = 'share?' + stringUtil.stringParams(_conf);
    schemeUtil.doAppFunc(path);
  }

  /**
   * 调起app分享图片带二维码
   * qr 二维码
   * image 图片
   * title 标题
   * content 内容
   * hint 底部提示
   * callback 回调
   */
  sharePic(picConf) {
    let _conf = Object.assign({ callback: 'pictureCallback' }, picConf);
    // newyear picture
    let path = 'newyear?' + stringUtil.stringParams(_conf);
    schemeUtil.doAppFunc(path);
    // 'tkanmanapp://picture?' +
    // 'qr=https%3A%2F%2Fwww.kanman.com%2F25934%2Fdpcq_1h.html' +
    // '&image=http%3A%2F%2Fmhpic.jumanhua.com%2Fcomic%2FD%2F%25E6%2596%2597%25E7%25A0%25B4%25E8%258B%258D%25E7%25A9%25B9%25E6%258B%2586%25E5%2588%2586%25E7%2589%2588%2F722%25E8%25AF%259DGQ%2F1.jpg-kmh.middle.webp' +
    // '&title=%E6%96%97%E7%A0%B4%E8%8B%8D%E7%A9%B9' +
    // '&content=%E7%AC%AC%E4%B8%80%E8%AF%9D' +
    // '&hint=%E9%95%BF%E6%8C%89%E8%AF%86%E5%88%AB%E4%BA%8C%E7%BB%B4%E7%A0%81' +
    // '&callback=picturecallback'
  }

  /**
   * 配置app分享（app右上角三个点分享）
   **/
  setShareConfFn(shareConf = {}) {
    let _conf = Object.assign({}, conf.shareConf, shareConf);
    // 处理分享url
    let url = _conf.url || location.href;
    const pName = clientUtil.getPlatformName();

    // 分平台基础配置
    const platformConfig = clientUtil.getPlatformConfig();
    if (pName) {
      url = url.replace('pName=' + pName, 'pName=' + platformConfig.sn);
    } else {
      url = url + (url.indexOf('?') > -1 ? '&' : '?') + 'pName=' + platformConfig.sn;
    }
    // console.log({
    //   title: _conf.title,
    //   content: _conf.content,
    //   url: url,
    //   callback: _conf.callback,
    //   image: _conf.image || platformConfig.image
    // });
    _conf.url = url;
    window.getShareConf = () => {
      return {
        title: _conf.title,
        content: _conf.content,
        url: url,
        callback: _conf.callback,
        image: _conf.image || platformConfig.image
      };
    };
  }
}

export default new Share();
