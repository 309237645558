/**
 * Url相关
 * 获得url参数|跳转至下载页|构建下载页链接
 */

import util from './utils'; // cnzz之类的用户头像路径拼装之类的
import clientUtil from './client';

class Url {
  constructor() {}

  /**
   * @function parseQuery 获得url参数
   * @param query 请求query 默认为location.search
   * @returns {Object} 返回解析后的请求参数json对象
   */
  parseQuery(query = location.search) {
    const searchArr = query.substr(1).split(/=|&/);
    const objQuery = {};
    searchArr.forEach((item, index) => {
      if (index % 2 === 0 && searchArr[index + 1]) {
        objQuery[item] = searchArr[index + 1];
      }
    });
    return objQuery;
  }

  /**
   * @function queryStringify 获得url字符串参数
   * @param  {Object} params
   * @returns {String} 返回URL参数
   */
  queryStringify(params) {
    if (util.getType(params) !== 'object') {
      throw new Error('参数错误，不是有效的json对象！');
    }
    let tempArr = [];
    for (let key in params) {
      let value = params[key] || '';
      // 如果存在中文字符对url进行编码
      if (/[\u4e00-\u9fa5]+/.test(value)) {
        value = encodeURIComponent(value);
      }
      tempArr.push(`${key}=${value}`);
    }
    return tempArr.join('&');
  }

  /**
   * 跳转至下载页
   */
  gotoDownloadPage(pkgname) {
    window.location.href = `https://app.321mh.com/app/scheme?pkgname=${pkgname}`;
  }

  /**
   * 构建跳转至下载页的链接
   * activeName, encodeScheme, domain = 'https://app.321mh.com/app/scheme', id, baseConfig
   */
  buildSchemeUrl(
    encodeScheme,
    domain = 'https://app.321mh.com/app/scheme',
    id,
    activeName,
    extraObj = {}
  ) {
    const { os } = clientUtil.getClientInfo();
    const { appId, pkgname } = clientUtil.getPlatformConfig();
    id = id || appId[os];
    return `${domain}?pkgname=${pkgname}&channeCode=${id}${
      activeName ? `&cnzz=${activeName}` : ''
    }&ios_scheme=${encodeScheme}&android_schema=${encodeScheme}${
      extraObj.autoCall ? '' : `&autocall=1`
    }`;
  }
}

export default new Url();
