<template>
  <transition name="r-mask">
    <div class="page-loading" v-show="show">
      <div class="loading-content">
        <ul class="animate effect-4" id="grid">
          <li><img class="img" src="@/imgs/loading/loading.gif" /></li>
          <!-- <li><img class="img" src="@/imgs/loading/ico_loading2.png"></li>
      <li><img class="img" src="@/imgs/loading/ico_loading3.png"></li>
       <li><img class="img" src="@/imgs/loading/ico_loading4.png"></li>
        <li><img class="img" src="@/imgs/loading/ico_loading5.png"></li>
          <li><img class="img" src="@/imgs/loading/ico_loading6.png"></li>
            <li><img class="img" src="@/imgs/loading/ico_loading7.png"></li>
              <li><img class="img" src="@/imgs/loading/ico_loading8.png"></li> -->
        </ul>
        <div class="t-c">加载中...</div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'PageLoading',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const show = ref(false);
    return {
      showloading: props.show
    };
  }
});
</script>


<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.page-loading {
  width: 100vw;
  height: 100vh;
  background: $bc;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  text-align: center;
  font-size: 14px;
}
.loading-content {
  margin-top: 250px;
}
.img {
  width: 70px;
}
</style>
