// 引导下载（##apollo会根据此文件动态替换部分节点，生成index.js##）
export default {
  app: {
    kmh: {
      ckey: 'CK1382108356199', // 看漫画微下载: CK1382108356199
      ios: 'id1507123649', // 看漫画IOS: id1303470988
      appname: '看漫',
      wap_url: 'https://m.kanman.com',
      iosurl: 'https://comicsdk.321mh.com/kmh_app',
      pkgname: 'com.wbxm.icartoon',
      scheme_Adr: 'tkanmanapp',
      sn: 'kmh',
      image: 'https://resource.mhxk.com/public/kanman_logo.png',
      version: '2.2.5',
      iosVersion: '2.6.0',
      diamonds: '钻石',
      coin: '金币',
      user: '主人',
      android: {
        huangjin: '黄金免费卡',
        heijin: '黑金免费卡'
      },
      iphone: {
        huangjin: '水晶看漫通',
        heijin: '星钻看漫通'
      },
      appId: {
        // 指产品id
        default: 1,
        android: 3,
        iphone: 4,
        ipad: 5
      }
    },
    mht: {
      ckey: 'CK138297596322', // 漫画台微下载: CK138297596322
      ios: 'id1510313064', // 漫画台IOS: id1243372811
      // 漫画台下载看漫
      // iosurl: 'https://comicsdk.321mh.com/mht_app',
      iosurl: 'https://comicsdk.321mh.com/kmh_app',
      appname: '漫画台',
      wap_url: 'http://m.manhuatai.com',
      pkgname: 'com.comic.manhuatai',
      scheme_Adr: 'tmanhuataiapp',
      sn: 'mht',
      image: 'https://resource.mhxk.com/public/share/share_logo_mht.png',
      version: '1.7.4',
      iosVersion: '0',
      diamonds: '果币',
      coin: '萌币',
      user: '小主',
      android: {
        huangjin: '黄金免费卡',
        heijin: '黑金免费卡'
      },
      iphone: {
        huangjin: '黄金免费卡',
        heijin: '黑金免费卡'
      },
      appId: {
        // 指产品id
        default: 2,
        android: 8,
        iphone: 9,
        ipad: 10
      }
    },
    aym: {
      ckey: 'CK1383325844989', // 爱优漫微下载: CK1383325844989
      ios: 'id1499779789', // 爱优漫IOS: id1344658366
      appname: '爱优漫',
      wap_url: 'https://m.iyouman.com',
      iosurl: 'https://comicsdk.321mh.com/iym_app',
      pkgname: 'com.comic.iyouman',
      scheme_Adr: 'tiyoumanapp',
      sn: 'aym',
      image: 'https://resource.mhxk.com/public/share/share_logo_iym.png',
      version: '1.4.9',
      iosVersion: '0',
      diamonds: '水晶',
      coin: 'U币',
      user: '宝宝',
      android: {
        huangjin: '黄金免费卡',
        heijin: '黑金免费卡'
      },
      iphone: {
        huangjin: '黄金免费卡',
        heijin: '黑金免费卡'
      },
      appId: {
        // 指产品id
        default: 4,
        android: 18,
        iphone: 19,
        ipad: 20
      }
    },
    smh: {
      ckey: 'CK1383322645544', // 神漫画微下载: CK1383322645544
      ios: 'id1342099056', // 神漫画IOS:id1342099056
      iosurl: 'https://comicsdk.321mh.com/smh_app',
      appname: '神漫画',
      wap_url: 'https://m.taomanhua.com',
      pkgname: 'com.comic.shenmanhua',
      scheme_Adr: 'tshenmanhuaapp',
      sn: 'smh',
      image: 'https://resource.mhxk.com/public/share/share_logo_smh.png',
      version: '1.4.9',
      iosVersion: '0',
      diamonds: '银票',
      coin: '铜钱',
      user: '上神',
      android: {
        huangjin: '黄金免费卡',
        heijin: '黑金免费卡'
      },
      iphone: {
        huangjin: '黄金免费卡',
        heijin: '黑金免费卡'
      },
      appId: {
        // 指产品id
        default: 3,
        android: 13,
        iphone: 14,
        ipad: 15
      }
    },
    asmh: {
      ckey: 'CK1383328534446', // 爱飒漫画
      ios: 'id1344659342', // 爱飒漫画ISO: id1344659342
      iosurl: 'https://comicsdk.321mh.com/ismh_app',
      appname: '爱飒漫画',
      wap_url: 'https://m.isamanhua.com',
      pkgname: 'com.comic.isaman',
      scheme_Adr: 'tisamanapp',
      sn: 'asmh',
      image: 'https://resource.mhxk.com/public/share/share_logo_ism.png',
      appId: {
        // 指产品id
        default: 6,
        android: 30,
        iphone: 31,
        ipad: 32
      }
    },
    zymk: {
      ckey: 'CK1353950509167',
      ios: 'id1012491820',
      iosurl: 'https://comicsdk.321mh.com/zymk_app',
      appname: '知音漫客',
      wap_url: 'https://m.zymk.cn',
      pkgname: 'cn.zymk.comic',
      scheme_Adr: 'zymkapp',
      sn: 'zymk',
      image: 'https://m.zymk.cn/static/images/default/river/zymk_topbar_icon.png',
      appId: {
        // 指产品id
        default: 6,
        android: 30,
        iphone: 31,
        ipad: 32
      }
    },
    // 漫客栈 非标准
    mkzhan: {
      ckey: 'CK1383328534446',
      ios: 'id1012491820',
      iosurl: 'https://comicsdk.321mh.com/mkzhan_app',
      appname: '漫客栈',
      wap_url: 'https://m.mkzhan.com',
      pkgname: 'com.xmtj.mkz',
      scheme_Adr: 'mkzhan',
      sn: 'mkzhan',
      image: 'https://m.zymk.cn/static/images/default/river/mkzhan_topbar_icon.png',
      appId: {
        // 指产品id
        default: 6,
        android: 30,
        iphone: 31,
        ipad: 32
      }
    },
    // 全免漫画
    qmmh: {
      ckey: 'CK1383328534446',
      ios: 'id1012491820',
      iosurl: 'https://comicsdk.321mh.com/qmmh_app',
      appname: '全免漫画',
      wap_url: 'https://m.qmmh.com',
      pkgname: 'com.kanman.allfree',
      scheme_Adr: 'allfreeapp',
      sn: 'qmmh',
      image: 'https://m.zymk.cn/static/images/default/river/qmmh_topbar_icon.png',
      appId: {
        // 指产品id
        default: -1,
        android: 123,
        iphone: 124,
        ipad: 125
      }
    },
    kaimh: {
      appname: '恺漫画',
      pkgname: 'com.comic.kaimanhua',
      scheme_Adr: 'tkaimanhuaapp',
      sn: 'kaimh'
    }
  },
  image: {
    // head: 'http://image.mhxk.com/file/kanmanhua_images/head/{0}.jpg',
    cover: 'https://image.mhxk.com/mh/{0}.jpg-noresize',
    coverMid: 'https://image.mhxk.com/mh/{0}.jpg-300x400.jpg',
    coverMin: 'https://image.mhxk.com/mh/{0}.jpg-150x200.jpg',
    headQmCover: 'https://head.3456mh.com/quanmian/user_head/{0}.jpg-100x100',
    headCover: 'https://head.samanlehua.com/kmh_user_head/{0}.jpg-100x100',
    zymk: 'https://image.zymkcdn.com/file/head/{0}.jpg-100x100'
  },
  // 活动支持平台（一般情况为看漫系安卓、太极看漫，变化后可修改）
  supportPlatform: {
    android: ['kmh', 'smh', 'mht', 'aym'],
    ios: ['kmh']
  }
};
