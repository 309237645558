<template>
  <!-- toast弹框 -->
  <transition name="acgn-toast">
    <div :class="`acgn-toast${toastConf.isRadius ? ' toast-radius' : ''}`" v-show="toastConf.show">
      <div
        class="acgn-toast-txt"
        :style="toastConf.style"
        :class="toastConf.className"
        v-html="toastConf.message"
      ></div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  name: 'toast',
  setup() {
    const data = reactive({
      toastConf: {
        show: false,
        message: '',
        duration: 800,
        timer: 0,
        className: ''
      }
    });
    return {
      ...toRefs(data)
    };
  }
});
</script>

<style lang="scss">
/* toast */
.acgn-toast {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  z-index: 9999;
  transition: opacity 0.3s linear;
  // 闪屏
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  &.toast-radius {
    border-radius: 1000px;
  }
  &-txt {
    color: #fff;
    padding: 0.2rem;
    font-size: 0.4rem;
    line-height: 1.5;
    text-align: center;
  }
}

.ws-n {
  white-space: nowrap;
}

/* 动画 */
.toast-enter-active {
  transition: opacity 0.3s ease;
}

.toast-leave-active {
  transition: opacity 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

/*.toast-enter-active, .toast-leave-active {*/
/*}*/

.toast-enter,
.v-leave-to {
  opacity: 0;
}
</style>
