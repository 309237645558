/**
 * 字符串相关
 * 数字id生成文件路径|根据id处理图片路径|参数序列化|字符串前方自动补充字符
 */

import conf from '../constants'; // 平台基本信息
import utils from './utils';

class String {
  constructor() {}

  /**
   * 根据数字id生成文件路径，补齐至9位后每3位数字一级目录
   * 例如：id为12345，则文件路径为000/012/345
   **/
  getDirById(id = '') {
    id = id + '';
    if (id.length < 9) {
      id = ('000000000' + id).slice(-9);
    }
    let str = '';
    for (; id.length > 3; ) {
      str = '/' + id.slice(-3) + str;
      id = id.slice(0, id.length - 3);
    }
    if (id) {
      str = id + str;
    }
    return str;
  }

  /**
   * 根据id处理图片路径
   * https://image.mhxk.com/file/kanmanhua_images/head/{0}.jpg
   * https://image.mhxk.com/mh/{0}.jpg-noresize
   */
  process4path(id, url = conf.image.coverMid) {
    return url.replace(/{(\d+)}/g, id);
  }

  /**
   * 将参数序列化
   * @param e
   */
  stringParams(e) {
    if (!e) return '';
    let temp = [];
    for (let key in e) {
      if (e.hasOwnProperty(key)) {
        temp.push(`${key}=${e[key]}`);
      }
    }
    return temp.join('&');
  }

  /**
   * @desc 字符串前方自动补充字符
   * @str 传入字符串
   * @number 个数
   * @addstring  补入字符
   */
  addString(str, number, addstring) {
    return str.toString().padStart(number, addstring);
  }

  /**
   * @desc 秒转分钟秒
   * @number 传入秒
   */
  secondsToFullTime(value) {
    let seconds = parseInt(value); // 秒
    let minutes = 0; // 分
    // let hour = 0; // 小时

    if (seconds >= 60) {
      minutes = parseInt(seconds / 60);
      seconds = parseInt(seconds % 60);
      if (minutes >= 60) {
        // hour = parseInt(minutes / 60);
        minutes = parseInt(minutes % 60);
      }
    }
    seconds = parseInt(seconds);
    const secondText = seconds < 10 ? `0${seconds}` : seconds;
    const minutesText = minutes < 10 ? `0${minutes}` : minutes;
    return `${minutesText}:${secondText}`;
  }

  /**
   * 1s = 1000ms
   * 1m = 60 * 1000ms
   * 1h = 60 * 60 * 1000
   * 1d = 24 * 60 * 60 * 1000
   *
   * [getTimeSpan 转换阶梯时间]
   * @param  {[Number]} timestamp [时间戳]
   * @param  {[String]} fmt [需要显示的格式，例如yyyy-MM-dd hh:mm:ss]
   * @return {[String]}      [转换后的时间字符串]
   */
  getTimeSpan(timestamp, fmt) {
    const tempTimestamp = parseInt(timestamp, 10);
    if (tempTimestamp.toString().length === 10) {
      timestamp = tempTimestamp * 1000;
    }
    const nowDate = new Date();
    const current = nowDate.getTime();
    const differe = current - timestamp; // 差值
    if (differe > 0) {
      if (differe > 864e6) {
        // 10天前，显示mm.dd hh: mm（例：04.05 16:05）
        // return this.formatDate(timestamp, 'mm.dd hh:mm')
        return utils.formatDate(timestamp, fmt || 'yyyy.MM.dd');
      } else if (differe > 2592e5) {
        // 小于10天（含10天），显示x天前；
        return parseInt(differe / 864e5, 10) + '天前';
      } else if (differe > 1728e5) {
        // 2天到3天之前前天；
        return '前天';
      } else if (differe > 864e5) {
        // 1天到2天之间，显示昨天；
        return '昨天';
      } else if (differe > 36e5) {
        // 小于一天，显示x小时前；
        return parseInt(differe / 36e5, 10) + '小时前';
      } else if (differe > 6e4) {
        // 小于1小时，显示x分钟前；
        return parseInt(differe / 6e4, 10) + '分钟前';
      }

      // 小于1分钟，显示“刚刚”；
      return '刚刚';
    } else {
      return utils.formatDate(timestamp, fmt || 'yyyy.MM.dd');
    }
  }
}

export default new String();
